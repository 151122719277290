// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font1 {
    
    font-family:'Times New Roman', Times, serif

}

.font2 {

    font-family: monospace;

}

.font3 {

    font-family: 'Courier New', Courier, monospace;

}
`, "",{"version":3,"sources":["webpack://./src/components/FontDinamica.css"],"names":[],"mappings":"AAAA;;IAEI;;AAEJ;;AAEA;;IAEI,sBAAsB;;AAE1B;;AAEA;;IAEI,8CAA8C;;AAElD","sourcesContent":[".font1 {\n    \n    font-family:'Times New Roman', Times, serif\n\n}\n\n.font2 {\n\n    font-family: monospace;\n\n}\n\n.font3 {\n\n    font-family: 'Courier New', Courier, monospace;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
